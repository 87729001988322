import { forwardRef } from 'react';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

import Spinner from './Spinner';

import type { ButtonHTMLAttributes, DetailedHTMLProps, SVGProps } from 'react';

interface ButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  children: React.ReactNode;
  isLoading?: boolean;
  variant?: 'primary' | 'secondary' | 'dark' | 'outline';
  icon?: React.FunctionComponent<SVGProps<SVGSVGElement>>;
}

export default forwardRef<HTMLButtonElement, ButtonProps>(function Button(
  { icon: Icon, children, isLoading, variant = 'primary', ...props },
  ref,
) {
  return (
    <button
      {...props}
      ref={ref}
      disabled={isLoading || props.disabled}
      className={twMerge(
        clsx(
          `text-sm rounded-md px-4 flex justify-center items-center h-10 enabled:hover:opacity-90 focus:outline-none whitespace-nowrap ${props.className}`,
          {
            'bg-primary text-white': variant === 'primary',
            'border border-gray-200 text-primary': variant === 'secondary',
            'bg-gray-700 text-white': variant === 'dark',
            'border border-gray-200': variant === 'outline',
            '!bg-gray-300 !text-gray-500': isLoading || props.disabled,
          },
        ),
      )}
    >
      {isLoading ? (
        <Spinner
          className={clsx({
            'fill-black': variant == 'outline',
            'fill-white': variant != 'outline',
          })}
        />
      ) : (
        <div className="flex items-center gap-3">
          {Icon && <Icon className="w-3.5 h-3.5" />}
          {children}
        </div>
      )}
    </button>
  );
});
